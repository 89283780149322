.OuterCircle {
    position: absolute;
    right: 1em;
    bottom: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #f00;
    border-radius: 2em;
    width: 3em;
    height: 3em;
    background-color: #3f0000;
}

//Media query for everything smaller than 600 pixels in width
@media (max-width: 600px) {
    .OuterCircle {
        bottom: 7em;
    }
}

.DownArrow {
    margin: 0.2em;
}

.DownArrow > h1 {
    margin: 0;
    color: #fff;
}