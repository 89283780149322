.maxHalfWidth {
    max-width: 50em;
    margin: 2%;
}

.FlexCenterContent {
    display: flex;
    justify-content: center;
}

.Grid2Container {
    display: grid;
    grid-template-columns: 40vw;
    margin: auto;
    padding: 1em;
    max-width: 1200px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
}

.Grid3Container {
    display: grid;
    grid-template-columns: 40vw;
    width: 100%;
    margin: auto;
    max-width: 1200px;
    grid-template-columns: repeat(3, 1fr);
    height: fit-content;
}

.Grid1Container {
    display: grid;
    grid-template-columns: 40vw;
    width: 100%;
    margin: auto;
    max-width: 1200px;
    grid-template-columns: repeat(1, 1fr);
}

.inGridList {
    text-align: left;
    padding-left: 2em;
    text-align: left;

    .listing {
        padding-left: 1em;
    }

    .bulletListing {
        margin-left: 2em;
        display: list-item;  
    }
}

.inGridBox {
    grid-column: 1;
    grid-row: 1;
    text-align: center;
    margin: auto;
}

.leftDiv {
    text-align: left;
    width: fit-content;
    margin: auto;
}