.center {
    text-align: center;
    color: white;
    text-shadow: black;
}

.left {
    display: inline-block;
    text-align: left;
}

.red_clickable {
    color: red;
    cursor: pointer;
}