.App {
  text-align: center;
  background-color: #262626;
  color: white;
  margin: 0;
  max-height: 100vh;
  max-width: 100vw;
}

@media only screen and (max-width: 621px) { /*Phone*/
  .MainPage {
    padding-bottom: 8em;
    padding-top: 2em;
  }
}

@media only screen and (min-width: 621px) { /*Desktop*/
  .MainPage {
    padding-bottom: 2em;
    padding-top: 6em;
  }
}