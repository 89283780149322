@import url('https://fonts.googleapis.com/css?family=Roboto');

#navbar-animmenu>ul>li.active>a{
	color: #F00;
	background-color: transparent;
}

#navbar-animmenu {
	background: #7F0000;
	float: left;
	overflow: hidden;
	padding: 10px 0px;
	width: 100vw;
    box-sizing: border-box;
	padding-left: 2em;
	position: absolute;
	top: 0;
	z-index: 100;
}

#navbar-animmenu ul{
	padding: 0px;
	margin: 0px;
}

#navbar-animmenu ul li a i{
	margin-right: 10px;
}

#navbar-animmenu li {
	list-style-type: none;
	float: left;
}

#navbar-animmenu a:not(:only-child):after {
	content: "\f105";
	position: absolute;
	right: 20px;
	top: 10%;
	font-size: 14px;
	font-family: "Font Awesome 5 Free";
	display: inline-block;
	padding-right: 3px;
	vertical-align: middle;
	font-weight: 900;
}

#navbar-animmenu .active>a:not(:only-child):after {
	transform: rotate(90deg);
}

.hori-selector{
	display:inline-block;
	position:absolute;
	height: 100%;
	top: 10px;
	left: 2em;
	background-color: #262626;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}
.hori-selector .right,
.hori-selector .left{
	position: absolute;
	width: 25px;
	height: 25px;
	background-color: #262626;
	bottom: 10px;
}
.hori-selector .right{
	right: -25px;
}
.hori-selector .left{
	left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before{
	content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #7F0000;
}
.hori-selector .right:before{
	bottom: 0;
    right: -25px;
}
.hori-selector .left:before{
	bottom: 0;
    left: -25px;
}

#navbar-animmenu ul li a{
	color: #000;
    text-decoration: none;
    font-size: 1em;
    line-height: 45px;
    display: block;
    padding: 0px 20px;
    position: relative;
	font-weight: 500;
}

/* Smartphones (portrait) ----------- */
@media only screen 
and (max-width : 621px) {
    #navbar-animmenu {
		position: absolute;
		bottom: 0;
		top: auto;
		max-height: 8em;
	}

	.hori-selector .right{
		visibility: hidden;
	}
	.hori-selector .left{
		visibility: hidden;
	}

	.hori-selector{
		position:absolute;
		height: 40%;
		left: 2em;
		background-color: #262626;
		border-radius: 15px;
	}
}