.redLink {
    color: #7F0000;
    text-decoration: none;
    cursor: pointer;
}

.darkBlueLink {
    color: #2A4B9B;
    text-decoration: none;
    cursor: pointer;
}

.redLink:hover {
    color: #FF0000;
}

.blueLink {
    color: #289292;
    text-decoration: none;
}

.blueLink:hover {
    color: #00ffff;
}

.invisibleLink {
    text-decoration: none;
    color: white;
}

.invisibleLink:hover {
    color: #FF0000;
}

.FancyBackButton {
    background-color: #7F0000;
    color: white;
    padding: 5px;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
}

.FancyBackButton:hover {
    background-color: red;
    color: black;
    padding: 5px;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
}

.ArduLink {
    color:#FF0000;
    margin-left: 1em;
}

.InoLink {
    color:#00FF00;
    margin-left: 1em;
}

.AzurLink {
    color:#5555FF;
    margin-left: 1em;
}