.flex_box {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 50%;
    margin: auto;
    min-width: 20em;
    height: auto;
}

.square_container {
    border-radius: 3.5em;
    margin: 1em;
    display: flex;
    width: 7em;
    height: 7em;
    border: 1px solid gray;
    text-align: center;
    transition: 0.3s;
}

.square_container:hover {
    border-radius: 2em;
    background-color: #300;
    border: 1px solid black;
}

.square_container:hover > h3 > a {
    color: #F00;
}

.square_container > * {
    margin: auto;
}


.square_container_red {
    border-radius: 3.5em;
    margin: 1em;
    display: flex;
    width: 7em;
    height: 7em;
    border: 1px solid gray;
    text-align: center;
    transition: 0.3s;
}

.square_container_red:hover {
    border-radius: 2em;
    background-color: #300;
    border: 1px solid black;
}

.square_container_red:hover > h3 > a {
    color: #F00;
}

.square_container_red > * {
    margin: auto;
}

.square_container_blue {
    border-radius: 3.5em;
    margin: 1em;
    display: flex;
    width: 7em;
    height: 7em;
    border: 1px solid gray;
    text-align: center;
    transition: 0.3s;
}

.square_container_blue:hover {
    border-radius: 2em;
    background-color: #003;
    border: 1px solid black;
}

.square_container_blue:hover > h3 > a {
    color: #88F;
}

.square_container_blue > * {
    margin: auto;
}