.fitContent {
    width: fit-content;
    margin: auto;
}

.redHighlight {
    color: red;
}

.hasFootNote {
    margin-bottom: 0px;
}

.footNote {
    font-size: 0.6em;
    color: white;
    margin: 0px;
}

.text_header {
    margin: 1em 0 0 0;
}

.block_left {
    margin: 0em 1em;
    text-align: left;
}

.center_text {
    text-align: center;
}

.centered_text_container {
    text-align: center;
    width: 100%;
    max-width: 50em;
    margin: auto;
}

.spaced_text_container {
    text-align: center;
    width: 100%;
    max-width: 50em;
    margin: auto;
    margin-bottom: 1em;
}

.title_text {
    margin-bottom: 3em;
}