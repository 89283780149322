.bottomLine {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #333;
    display: flex;
    justify-content: space-around;
    color: white;
    box-shadow: 0px -2px 5px #222;
}

.bottomLink {
    color: #AAAAAA;
}

.hiddenLink {
    color: #CCCCCC;
    text-decoration: none;
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 621px) {
    .bottomLine {
        bottom: auto;
        top: 0;
        box-shadow: 0px 2px -5px #222;
    }
}