.inGridContainer {
    text-align: left;
    padding-left: 1em;
    text-align: left;

    .leftDiv {
        text-align: left;
        width: 100%;
        margin: auto;
    }
}

.listing {
    padding-left: 1em;
}

.bulletListing {
    margin: 0.75em 2em;
    display: list-item;
}

@media only screen and (max-width: 768px) { /*Phone*/
    .GridContainer {
        display: grid;
        grid-template-columns: 40vw;
        width: 100%;
        margin: auto;
        max-width: 1200px;
        grid-template-columns: repeat(2, 1fr);
        height: fit-content;
    }

    .commissionImage {
        display: none;
        width: 0;
        height: 0;
    }
}

@media only screen and (min-width: 768px) { /*Desktop*/
    .FlexContainer {
        display: flex;
        flex-flow: row wrap;
        
        
        .fixedSizeDiv {
            width: 9em;
            text-align: left;
        }
    } 
    
    .GridContainer {
        display: grid;
        grid-template-columns: 40vw;
        width: 100%;
        margin: auto;
        max-width: 1200px;
        grid-template-columns: repeat(3, 1fr);
        height: fit-content;
    }
    
    .commissionImage {
        max-height: 60vh;
        margin: auto;
        margin-top: 1em;
    }
}

