.SonaContainer {

    margin: 0 2em 0 2em;
    text-align: left;

    .SonaRow {
        display: flex;
        width: 100%;
        background: gray;
        border: 1px solid black;
        align-content: flex-start;

        .SonaDescriptor {
            .SonaImage {
                max-width: 29vh;
                grid-column: 0;
            }
        }

        .smolDescriptor {
            font-size: 0.6em;
            color: black;
        }
        
        .SonaLore {
            font-size: 1em;
            color: black;
            padding-left: 1em;
            text-align: left;
            white-space: pre-line;
        }
    }
}

/* Smartphones (portrait) ----------- */
@media only screen 
and (max-width : 621px) {
    .SonaContainer {

        margin: 0 1em;
    
        .SonaRow {
            display: block;

            .SonaDescriptor {
                margin: 0;
                text-align: center;

                .SonaImage {
                    margin: 1em auto 0em auto;
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}