.impressum {
    text-align: left;
    padding: 0 1em;

    a {
        color: #7F0000;
        text-decoration: none;
    }
    
    a:hover {
        color: #FF0000;
    }
}