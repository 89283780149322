#project_list {
    display: flex;
    width: 90%;
    max-width: 50em;
    margin: auto;
}

@media screen and (max-width:54em) {
    #project_list {
        flex-direction: column;
    }
}

.project_tile_parent {
    margin: auto;
    display: block;
    width: 22em;
    height: 22em;
    z-index: 0;
}

.project_tile {
    display: flex;
    flex-direction:column;
    margin: 1em;
    width: 20em;
    height: 20em;
    border: 2px solid #7F0000;
    border-radius: 2em;
    text-align: center;
    position: relative;
    transition: 0.5s;
}

.project_tile:hover {
    margin: 1em;
    scale: 110%;
}

.project_tile > img {
    max-width: 90%;
    max-height: 65%;
    margin: auto;
    display: block;
}

.link_list {
    display: flex;
    flex-direction: column-reverse;
    flex:1;
    padding-bottom: 1em;
    padding-top: 1em;
}